const idPortalApi = "be64fdd3e511e4d6b36d58b24fb0eec8822c6de3";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "https://www.cmmatoes.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "cmmatoes.ma.gov.br/",
  NomePortal: "Matões",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 6, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `https://www.youtube.com/@camaradematoes3703`,
  link_localize: `https://maps.app.goo.gl/J2qwH3Ckd1WbSYzF9`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31770.405277675018!2d-43.2046205!3d-5.5223286!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x78f76685910025f%3A0xb11f812ce55048e1!2sMat%C3%B5es%2C%20MA%2C%2065645-000!5e0!3m2!1spt-BR!2sbr!4v1721821323191!5m2!1spt-BR!2sbr",
};
